import React from "react";
import GoogleMapReact from "google-map-react";
import { Box, Image } from "@chakra-ui/react";
import LazyLoad from "react-lazyload";
import { commonGoogleMapPinImage } from "@src/constants/images";

interface Props {
  lat: number;
  lng: number;
  zoom?: number;
  style?: React.CSSProperties;
}

export default function GoogleMap({ lat, lng, zoom }: Props) {
  return (
    <LazyLoad
      once
      placeholder={<Box w="100%" h={300} backgroundColor="gray.200" />}
    >
      <div style={{ height: "300px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCN5Gy180Ta4BvPKq4njtPkysUBjSeAoU8" }}
          defaultCenter={{ lat, lng }}
          defaultZoom={zoom || 15}
        >
          <Marker lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>
    </LazyLoad>
  );
}

interface MarkerProps {
  lat: number;
  lng: number;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
function Marker(_: MarkerProps) {
  return (
    <Box position="absolute" transform="translate(-50%, -50%)">
      <Image src={commonGoogleMapPinImage.src} w="40px" mt={-1} />
    </Box>
  );
}
